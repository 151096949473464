body {
  background-color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: auto;
  width: 100%;
  height: 100%;
  animation: pulse 25s;
}

@keyframes pulse {
  0% {
    background-color: #0d0941;
  }
  100% {
    background-color: #000000;
  }
}

#root {
  width: 100%;
  height: 100%;
}

p {
  color: white;
  margin: 0.5em auto;
}

h1 {
  margin: 0em auto 0.25em auto;
  font-size: 5em;
  text-align: center;
  font-family: cursive;
}

h2 {
  font-size: 3em;
  padding: auto auto 0em auto;
  margin: auto auto 0em auto;
}

h3 {
  color: white;
}

h4 {
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1em;
  font-size: small;
}

header {
  display: flexbox;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  padding: 0em;
  margin: auto;
  width: 100%;
  position: absolute;
  top: 0em;
  height: 12em;
}

.headerbackhome:hover {
  font-size: 5em;
  cursor: pointer;
  width: fit-content;
}

@media (max-width: 1100px) {
  header {
    font-size: 90%;
  }
}

@media (max-width: 600px) {
  header {
    font-size: 80%;
  }
}

@media (max-width: 380px) {
  header {
    font-size: 60%;
  }
}

button {
  background-color: white;
  font-weight: bolder;
  border-radius: 70%;
  width: 4em;
  height: 4em;
  font-size: 1em;
  margin: 0em auto 4em auto;
}

button:hover {
  cursor: pointer;
}

.navbar-nav {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin: auto;
  height: 1em;
  font-size: 1.25em;
  color: black;
  font-family: cursive;
  text-decoration: none;
}

@media (max-width: 845px) {
  .bg-light {
    background-color: rgba(0, 0, 0, 0) !important;
  }

  .navbar-toggler {
    background-color: white;
    height: 3em;
    width: 3em;
    margin-bottom: .5em;
  }
}

@media (max-width: 845px) {
  .navbar-nav {
    background-color: white;
   height: fit-content;
   width: fit-content;
   min-width: 10em;
   font-weight: bold;
   font-size: 3em;

  }
}
.singleImage {
  height: 18em;
  width: 18em;
  border-radius: 50%;
  border: 1em solid white;
  padding: 1em;
  margin: 0em;
}

/*------------- HOME PAGE------------------ */

.logo {
  margin: 13em auto 0em auto;
  width: 25%;
  height: 25%;
}

.homepage {
  margin: 1em auto;
  width: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
  font-size: 1.5em;
}

/* ----------------------- */
/* https://www.w3docs.com/snippets/css/how-to-create-a-typewriter-text-with-only-css.html */
.typewriter {
  font-family: monospace;
  font-size: 1em;
  letter-spacing: 0.17em;
  margin: 2em 1em;
  /* overflow: hidden;
  white-space: nowrap;
  border-right: 0.1em solid pink;
  animation: typing 4s steps(30, end); */
}

/* ----------------------------------- */
@media (max-width: 1900px) {
  .logo {
    margin: 12em auto 0em auto;
    width: 25%;
    height: 25%;
  }
}

@media (max-width: 1225px) {
  .logo {
    margin: 12em auto 0em auto;
  }
}

@media (max-width: 900px) {
  .logo {
    margin: 10em auto 0em auto;
    width: 55%;
    height: 55%;
  }

  .typewriter {
    font-size: 100%;
    white-space: normal;
  }
}

@media (max-width: 385px) {
  .logo {
    margin: 12em auto 0em auto;
    width: 65%;
    height: 65%;
  }

  .typewriter {
    font-size: 100%;
  }
}

.gettoknowme {
  background-color: white;
  font-size: 0.75em;
  border-radius: 50%;
  margin: 0em auto 0em auto;
  height: 5em;
  width: 5em;
  font-weight: bold;
  text-decoration: none;
  padding: 0.5em;
}

.gettoknowme:hover {
  font-weight: bolder;
  font-size: large;
  background-color: black;
  color: white;
  border: 0.15em solid white;
  cursor: pointer;
  background-image: url("https://media.giphy.com/media/3o7btWtWIcrnr6Nvwc/giphy.gif");
  background-size: 3em;
}

/*------------- ABOUT PAGE------------------ */

.logoCompanies {
  margin: 0.25em auto 0.25em auto;
  width: 8em;
  /* height: 15%; */
}

.logogrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 0.25em;
  align-items: center;
}
.headshot {
  margin: 10em auto 2em auto;
  border: 1em solid white;
  border-radius: 50%;
  height: 10em;
  width: 10em;
  border-radius: 50%;
  border: 0.5em solid white;
  padding: 0.5em;
}

.aboutMe {
  color: white;
  margin: 1em auto 1em;
  width: 80%;
  font-size: 0.75em;
}

@media (max-width: 1900px) {
  .headshot {
    height: 10em;
    width: 10em;
  }

  .logogrid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (max-width: 1225px) {
  .headshot {
    height: 8em;
    width: 8em;
    border: 0.75em solid white;
    padding: 1em;
  }

  .logogrid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 900px) {
  .logogrid {
    grid-template-columns: 1fr 1fr;
  }

  .headshot {
    margin: 10em auto 0em auto;
  }
}

@media (max-width: 500px) {
  .headshot {
    margin: 11em auto 0em auto;
    border: 0.5em solid white;
    padding: 0.75em;
  }

  .logogrid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 385px) {
  .headshot {
    margin: 9em auto 0em auto;
    height: 7em;
    width: 7em;
  }
}

/*------------- PROJECT PAGE------------------ */

/* .buttondiv{
 position: fixed;
} */
video {
  width: 25em;
  height: 12em;
}

.youtubevideo {
  width: 28em;
  height: 12em;
  border: none;
  margin: 0em auto;
  padding: 0em;
}

.projectview {
  margin: 18em 2em 0em 2em;
}

.projectcontainer {
  display: flexbox;
  justify-content: space-evenly;
  margin: auto;
  border: 0.25em solid white;
  padding: 2em 1em 1em 1em;
  width: 40em;
  min-height: 47em;
  height: fit-content;
}

.projectbutton {
  border-radius: 100%;
  width: 9em;
  height: 9em;
  margin: 1em;
  font-size: 0.7em;
  text-align: center;
  word-wrap: break-word;
  padding: 1.25em;
}

.projectbutton:hover {
  font-weight: bolder;
  background-image: url("https://media.giphy.com/media/3o7btWtWIcrnr6Nvwc/giphy.gif");
  background-size: 3em;
  color: white;
  border: 0.15em solid white;
  cursor: pointer;
}

.projectgrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-column-gap: 2em;
  grid-row-gap: 8em;
  color: white;
  margin: auto;
}

@media (max-width: 1500px) {
  .projectview {
    margin: 18em 2em 0em 2em;
  }

  .projectgrid {
    grid-template-columns: 1fr 1fr;
  }

  .projectcontainer {
    border: 0.25em solid white;
    width: 33em;
    min-height: 45em;
  }

  .projectbutton:hover {
    font-weight: bold;
    border: 0.25em solid white;
  }
}

@media (max-width: 1200px) {
  .projectview {
    margin: 16em 2em 0em 2em;
  }

  .projectgrid {
    grid-template-columns: 1fr 1fr;
  }

  .projectcontainer {
    border: 0.25em solid white;
    width: 33em;
    padding: 2em;
    font-size: 90%;
  }
}

@media (max-width: 1100px) {
  .projectview {
    margin: 18em 2em 0em 2em;
  }

  .projectgrid {
    grid-template-columns: 1fr 1fr;
  }

  .projectcontainer {
    border: 0.25em solid white;
    width: 32em;
    padding: 2em;
    font-size: 70%;
    min-height: 47em;
  }
}

@media (max-width: 900px) {
  .projectview {
    margin: 18em 2em 0em 2em;
  }

  .projectgrid {
    grid-template-columns: 1fr;
  }

  .projectcontainer {
    border: 0.25em solid white;
    height: fit-content;
    padding: 2em;
    font-size: 70%;
  }
}

@media (max-width: 550px) {
  .projectview {
    margin: 16em 1em 0em 1em;
  }

  .projectcontainer {
    padding: 1em;
    min-height: 30em;
  }
}

/*----------------RESUME PAGE -------------------*/

hr {
  margin-top: 3em;
  margin-bottom: 2em;
  background-color: white;
  height: 0.25em;
}
.resumeimage {
  margin: 1em auto 0em auto;
}

.resumebutton {
  margin: 1em auto 0em;
  padding: 0.5em;
  height: 6em;
  width: 6em;
}

/* iframe {
  width: 80%;
  height: 75em;
  border: 1.5em solid white;
  padding: 1.5em;
} */

@media (max-width: 550px) {
  .resumeimage {
    margin: 1em 1em 0em 1em;
  }

  .resumebutton {
    margin: 5em auto 0em;
  }

  iframe {
    display: none;
  }
}

/*------------- DISCOVERY PAGE------------------ */

.selector {
  margin: 20em auto 0em auto;
}

.selectorbutton {
  margin: 2em auto 2em auto;
  width: 5em;
  height: 5em;
}

.selectorbutton:hover {
  font-weight: bolder;
  background-image: url("https://media.giphy.com/media/3o7btWtWIcrnr6Nvwc/giphy.gif");
  background-size: 2em;
  color: white;
  border: 0.15em solid white;
  cursor: pointer;
}

select {
  display: flex;
  text-align-last: center;
  font-size: 1em;
  font-weight: bolder;
  background-color: white;
  min-width: 10em;
  height: 2em;
  margin: 3em auto 1em auto;
  padding: auto;
  cursor: pointer;
}

.myvideonotes {
  font-family: cursive;
  font-weight: lighter;
  color: yellow;
  font-size: 1.5em;
}

.myvideogrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.myvideos {
  margin: 0.25em;
  border: 0.5em solid white;
}

.myvideostitles {
  padding: 0em;
  margin: 2em auto 0em auto;
}

.myvideossubtitles {
  padding: 0em;
  margin: 0em auto;
  font-style: italic;
  font-size: 0.75em;
}

.giphyresults {
  border: 0.25em solid white;
  height: 12em;
  width: 12em;
  border-radius: 50%;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  grid-column-gap: 2em;
  grid-row-gap: 8em;
  color: white;
  font-size: 1.5em;
  margin: auto auto 2.5em auto;
}

@media (max-width: 1900px) {
  .grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .myvideogrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 1500px) {
  .myvideogrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 1225px) {
  .grid {
    grid-template-columns: 1fr 1fr;
  }

  .myvideogrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 900px) {
  .grid {
    grid-template-columns: 1fr;
  }

  .myvideogrid {
    grid-template-columns: 1fr;
  }

  .myvideos {
    width: 50%;
  }
}

/*----------------FOOTER-------------------------*/
footer {
  display: flexbox;
  align-items: center;
  justify-content: center;
  background: white;
  width: 100%;
  height: 6em;
  position: static;
  margin: 16em auto 0em auto;
  bottom: 0em;
  padding: 0em;
}

.footer {
  font-family: cursive;
  font-size: 1.5em;
  color: black;
  padding: 0em;
  margin: 0em;
}

.fa {
  padding: 0.5em;
  font-size: 1.5em;
  text-align: center;
  text-decoration: none;
  margin: 0.2em 0.5em 0em 0.5em;
  cursor: pointer;
  border-radius: 50%;
  background: black;
  color: white;
}

.fa:hover {
  opacity: 0.6;
}

/*----------------CONTACT PAGE ------------------------*/

.contactform {
  margin: 20em auto 0em auto;
}

.contactheader {
  font-size: 3em;
  font-family: cursive;
}
.forminput {
  width: 10em;
  margin: auto;
  text-align: center;
  font-size: 2em;
}

.formtextarea {
  width: 10em;
  margin: auto;
  text-align: center;
  font-size: 2em;
}

/*----------------404 PAGE ------------------------*/

.page404 {
  margin-top: 6.5em;
  color: white;
}

@media (max-width: 500px) {
  .simplepage {
    margin: 40em auto 5em auto;
    font-size: 80%;
  }
}
